import React, { Component } from 'react';
import titleSponsorImg from '../../assets/hero/titleSponsor.png';
import heroTextImg from '../../assets/hero/heroText.png';
import './Hero.scss';

import bakerWestImg from '../../assets/hero/bakerWest.svg';
import vccImg from '../../assets/hero/vcc.png';

class Hero extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        //
    }
    render() {
        return (
            <section className="hero-section">
                <div className="bgOverlay"></div>
                {/* <img className="titleSponsor" src={titleSponsorImg} alt="brivia group" /> */}
                <img className="heroText" src={heroTextImg} alt="once upon a shine gala"/>
                {/* <div className="presentedByWrapper">
                    <h4>presented by</h4>
                    <div className="presentedBy">
                        <div className="imgWrapper">
                            <img src={bakerWestImg} alt="bakerwest" />
                        </div>
                        <div className="imgWrapper">
                            <img src={vccImg} alt="vcc" />
                        </div>
                    </div>
                </div> */}
            </section>
        );
    }
}

export default Hero;