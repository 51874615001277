import React, { Component } from 'react';
import axios from 'axios';
import './Footer.scss'

class Footer extends Component {
    state = {
        socialData: [
            {
              "id": 1,
              "link": "https://www.facebook.com/SDCFdn",
              "icon": "fab fa-facebook-f"
            },
            {
              "id": 2,
              "link": "https://twitter.com/sdcfdn?lang=en",
              "icon": "fab fa-twitter"
            },
            {
              "id": 3,
              "link": "https://www.instagram.com/sdcfdn/",
              "icon": "fab fa-instagram"
            }
        ]
    }
    render() {
        return (
            <footer className="footer-area">
                {/* Footer Top */}
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left d-flex align-items-center">©2023 SDC Foundation, All Rights Reserved.</div>
                                    {/* Copyright Right */}
                                    <div className="social-icons d-flex">
                                        {this.state.socialData.map((item, idx) => {
                                            return (
                                                <a key={`sd_${idx}`} target="_blank" href={item.link}>
                                                    <i className={item.icon} />
                                                    <i className={item.icon} />
                                                </a>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;