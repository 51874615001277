import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './AboutGala.scss';


import slider1Img from '../../assets/about/slider1.jpg';
import slider2Img from '../../assets/about/slider2.jpg';
import slider3Img from '../../assets/about/slider3.jpg';
import slider4Img from '../../assets/about/slider4.jpg';
import slider5Img from '../../assets/about/slider5.jpg';


class AboutGala extends Component {
    state = {}
    componentDidMount(){
        //
    }
    render() {
        return (
            <section className="section-about-gala">
                <div className="container">
                    <iframe src="https://www.youtube.com/embed/1Ag-PXkKMfQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="about-gala container" id="gala">
                    <div className="row">
                        <div className="col-12 headingContainer">
                            <div className="headingWrapper">
                                <h2 className="heading">About Gala</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12">
                            Save the date for February 22, 2025, as we present the highly anticipated SDC 2025 Once Upon a Shine Gala, Presented by Beeta Education. Join us at Fairmont Pacific Rim for an extraordinary evening celebrating SDC's journey and achievements over the past year.
                            <br/>
                            <br/>
                            This enchanting gala serves as a platform for children of all abilities to share their stories, showcase their remarkable music and art from our transformative programs, and inspire all in attendance. It is an event that goes beyond the ordinary, working towards a vision that embraces diversity and inclusion, breaking free from outdated molds.
                            <br/>
                            <br/>
                            Prepare to be captivated by captivating exhibitions, breathtaking performances, insightful speeches, and an exciting auction. Every element of this magical evening has been carefully curated to create a lasting impact. By attending, you are not only enjoying a memorable experience but also contributing to our social programs and initiatives that empower children to thrive.
                            <br/>
                            <br/>
                            Be a part of this unforgettable occasion, where talent meets purpose and heartwarming stories intertwine with disability empowerment. Don't miss out on this inspiring and meaningful event that will leave an indelible mark on the lives of children with disabilities.
                            <br/>
                            <br/>
                            Tickets are now available for purchase. Join us on February 22, 2025, for the SDC 2025 Once Upon a Shine Gala, Presented by Beeta Education, and together let us continue empowering children to empower children with disabilities.
                            <br/>
                            </div>
                        <div className="buy-tickets-wrapper col-12 d-flex justify-content-center align-items-center">
                            {/* <div> */}
                                <Link className="btn ml-lg-auto btn-bordered-white" to="/buy-tickets">
                                    <i class="fa fa-ticket-alt" aria-hidden="true"></i>
                                    Buy Tickets
                                </Link>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className="about-sdc container" id="sdc">
                    <div className="sdcHeadingWrapper">
                        <div className="sdcHeading">
                            <div className="headingContainer">
                                <div className="headingWrapper">
                                    <h2 className="heading">About SDC</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12">
                            <div className="auctions-slides">
                                <div className="swiper-container slider-mid items">
                                    <div className="swiper-wrapper">
                                        {/* Single Slide */}
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider1Img} alt="" />
                                        </div>
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider2Img} alt="" />
                                        </div>
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider3Img} alt="" />
                                        </div>
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider4Img} alt="" />
                                        </div>
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider5Img} alt="" />
                                        </div>
                                    </div>
                                    <div className="swiper-pagination" />
                                </div>
                            </div>
                            <p>
                            SDC stands as a beacon of empowerment, nurturing a generation of compassionate youth who uplift children with disabilities. With an unwavering belief in equal opportunities for all, we amplify the voices of passionate young leaders who champion inclusivity and acceptance.                         </p>
                            <p>
                            At the heart of SDC lies a robust array of impactful social programs dedicated to the well-being of children with disabilities. Each program holds the power to transform lives and create lasting change. These programs include: Building Bridges with Music, Education Through Creativity, Education Through Virtual Reality, Drama for Diversity, MPM/JELIC, and our newest KPOP Dance class!. Learn more about each program on {` `} <a className="sdcWeb" href="https://www.socialdiversity.org/">our website!</a>
                            </p>
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutGala;